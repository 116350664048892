<template>
  <v-card flat rounded="0" class="timer-page">
    <v-card-title style="height: 56px" class="pa-0">
      <v-toolbar :color="$vuetify.theme.dark ? '' : systemDetails.themecolor">
        <v-form ref="weekNavigation" class="mx-auto" style="width: 100%">
          <v-row class="text-center pt-3" style="height: 56px">
            <v-btn class="mt-1 ml-2 mr-3" fab dark height="25" width="25" elevation="0" :color="systemDetails.textcolor" @click="prevWeek">
              <v-icon size="23" :color="systemDetails.themecolor">mdi-chevron-left</v-icon>
            </v-btn>
            <v-text-field class="customtext mr-3" dense outlined :label="$t('week')" :rules="weekValidation" @keypress.enter="goToCustomWeek" v-model="customWeek"
            @blur="goToCustomWeek"></v-text-field>
            <v-text-field class="customtext mr-3" dense outlined :label="$t('year')" :rules="yearValidation" @keypress.enter="goToCustomWeek" v-model="customYear"
            @blur="goToCustomWeek"></v-text-field>
            <v-btn class="mt-1 mr-4" fab height="25" width="25" elevation="0" :color="systemDetails.textcolor" @click="nextWeek">
              <v-icon size="23" :color="systemDetails.themecolor">mdi-chevron-right</v-icon>
            </v-btn>
            <v-btn class="mt-1 mr-5" style="font-size: 0.48rem" fab height="25" width="25" elevation="0" :color="systemDetails.textcolor" :style="`color: ${systemDetails.themecolor}`" @click="goToCustomWeek">
              {{ $t('go') }}
            </v-btn>
            <v-btn class="mr-2" fab height="28" width="28" style="margin-top: 2px" :color="systemDetails.textcolor" @click="goToToday">
              <v-icon size="23" :color="systemDetails.themecolor">mdi-calendar</v-icon>
            </v-btn>
          </v-row>
        </v-form>
      </v-toolbar>
    </v-card-title>
    <v-card-text style="height: calc(100% - 56px)" class="pa-0">
      <v-row no-gutters v-if="fieldsLoading || hoursList.length <= 0">
        <v-col v-for="n in 9" :key="n" cols="12" sm="6" lg="3">
        <v-sheet class="mt-2">
          <v-skeleton-loader class="mx-auto" type="list-item"></v-skeleton-loader>
        </v-sheet>
        </v-col>
      </v-row>
      <v-container v-else class="pa-0 px-1" fluid style="height: 100%">
        <v-card flat class="timer--wrapper" color="transparent" elevation="0" height="100%">
          <v-card-title class="pa-0 pt-1 flex-column align-baseline ml-0" style="height: 60px">
            <v-row style="width: 100%" class="ma-0">
              <v-col cols="12" class="pa-0 text-center text-subtitle-1 font-weight-bold">{{$t('totalWorkingHours')}}<v-chip x-small class="ma-1" color="silver">{{ getWeekTotalHours() }}</v-chip></v-col>
              <v-col cols="12" class="pa-0 text-center text-subtitle-1 font-weight-bold">{{$t('totalAbsenceHoursTimer')}}<v-chip x-small class="ma-1" color="silver">{{ totalAbsenceHours }}</v-chip></v-col>
              <!-- <v-col cols="12" class="text-subtitle-1 font-weight-bold text-center pa-0">{{ $t('WorkingHours') }}</v-col>
              <v-col cols="6" style="font-size: 0.85rem" class="text-center pa-0">{{$t('totalHours')}}<v-chip x-small class="ma-1 mt-0" color="silver">{{ getWeekTotalHours() }}</v-chip></v-col>
              <v-col cols="6" style="font-size: 0.85rem" class="text-center pa-0">{{$t('HourBank')}}<v-chip x-small class="ma-1 mt-0" color="silver">{{ $formatter.replaceDotWithComma(this.totalHourBank) }}</v-chip></v-col>
              <v-col cols="12" class="text-subtitle-1 font-weight-bold text-center pa-0">{{ $t('AbsenceHours') }}</v-col>
              <v-col cols="6" style="font-size: 0.85rem" class="text-center pa-0">{{$t('totalHours')}}<v-chip x-small class="ma-1 mt-0" color="silver">{{ totalAbsenceHours }}</v-chip></v-col>
              <v-col cols="6" style="font-size: 0.85rem" class="text-center pa-0">{{$t('HourBank')}}<v-chip x-small class="ma-1 mt-0" color="silver">{{ absenceHours }}</v-chip></v-col> -->
            </v-row>
          </v-card-title>
          <v-card-text class="pa-0 mt-3" style="font-weight: 500; height: calc(100% - 80px)">
            <div class="timer_module">
              <v-expansion-panels>
                <v-expansion-panel @change="expansionToggle" v-for="(item, index) in hoursList" :key="index">
                  <v-expansion-panel-header class="pa-3">
                    <span class="font-weight-bold">{{ item.short_day }}</span>
                    <!-- <span>{{ $formatter.filterDate(item.date) }}</span> -->
                    <span class="d-inline-block text-truncate" :title="item.date" style="max-width:180px;">{{ item.date }}</span>
                    <span class="mr-5"><v-chip class="ma-2" :color="item.is_today_before ? (item.total > 0 ? 'primary' : 'yellow')  : 'primary'" small>
                      {{ $formatter.replaceDotWithComma(item.total) }}</v-chip>
                    <v-icon v-if="item.hours.length === 1 && (item.hours[0].state === 'delivered' || item.hours[0].state === 'approved')" :class="[item.hours[0].state]" style="margin-right: -25px">mdi-checkbox-multiple-marked-circle</v-icon>
                    </span>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="text-end">
                    <template v-if="item.hours && item.hours.length">
                    <v-icon size="20" :color="$vuetify.theme.dark ? 'white' : systemDetails.themecolor" @click="openFormHandler($event, item.date)" class="mb-1">mdi-plus-circle-outline</v-icon>
                      <template v-if="item.hours.length === 1 && !showDetails">
                        <v-row style="border-top: 1px solid rgba(0,0,0,0.3); border-left: 1px solid rgba(0,0,0,0.3)" class="mb-2 mt-0 text-start">
                          <template v-for="(header, index) in tableHeaders">
                            <template v-if="header.value !== 'actions'">
                              <v-col class="table-border py-2 font-weight-bold" cols="6" :key="index + '1'">{{ $t(header.text) }}</v-col>
                              <v-col class="table-border py-2" style="font-weight: 400" cols="6" :key="index + '2'">{{ header.type === 'number' ? $formatter.replaceDotWithComma(item.hours[0][header.value]) : item.hours[0][header.value] }}</v-col>
                            </template>
                          </template>
                        </v-row>
                        <h4 class="mt-2 text-start" style="color: #2676d2" @click="showDetails = true">{{ $t('moredetails') }}</h4>
                      </template>
                      <v-simple-table v-if="item.hours.length !== 1 || (item.hours.length === 1 && showDetails)" class="hours-table text-start">
                        <thead>
                          <tr>
                            <th class="text-subtitle-2 font-weight-bold" v-for="(header, index) in tableHeaders" :key="index">{{ $t(header.text) }}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(hour, i) in item.hours" :key="i" style="cursor: pointer;">
                            <template v-for="(header, index) in tableHeaders">
                              <td v-if="header.value === 'actions'" :key="index" class="d-flex justify-center align-center" style="height:100%">
                                <template v-if="hour.state === 'approved'">
                                  <v-chip small class="ma-2" color="rgba(86, 177, 62, 0.65)" label text-color="white">
                                    <v-icon left>mdi-check-all</v-icon> {{$t('approved')}}
                                  </v-chip>
                                </template>
                                <template v-else-if="hour.state === 'delivered'">
                                  <v-chip v-if="true" small class="ma-2" color="#e0b517" label text-color="white">
                                    <v-icon left>mdi-check-all</v-icon> {{$t('delivered')}}
                                  </v-chip>
                                </template>
                                <template v-else>
                                  <v-btn icon color="primary" :key="reInit"><v-icon size="20" @click="getSingleRecordHandler({ id: hour._id })">mdi-pencil-outline</v-icon></v-btn>
                                  <v-btn @click="deleteHourHandler(hour._id)" icon color="error"><v-icon size="20">mdi-delete-outline</v-icon></v-btn>
                                  <v-btn @click="getCommentsHistory(hour._id)" icon color="teal"><v-icon size="20">{{commentsLoader ? 'mdi-spin mdi-loading' : 'mdi-chat'  }}</v-icon></v-btn>
                                </template>
                              </td>
                              <td v-else style="font-weight: 400" :key="index" :title="hour[header.value]">{{ header.type === 'number' ? $formatter.replaceDotWithComma(hour[header.value]) :  hour[header.value] || ''}}</td>
                            </template>
                          </tr>
                        </tbody>
                      </v-simple-table>
                    </template>
                    <h4 v-else class="py-1 d-flex justify-center" style="border: 1px solid rgba(0,0,0,0.3)">
                      <v-icon @click="openFormHandler($event, item.date)" size="20" :color="$vuetify.theme.dark ? '#fff' : systemDetails.themecolor" dark class="mr-5"> mdi-plus-circle-outline </v-icon>
                      {{ $t('noRecords') }}
                    </h4>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
          </v-card-text>
        </v-card>
        <v-row class="mt-5 float-right">
          <v-speed-dial v-model="fab" right direction="left" transition="slide-y-transition" bottom="bottom" fixed class="timer_speed_dial_position"  style="z-index: 5">
            <template v-slot:activator>
              <v-btn small v-model="fab" color="blue darken-2" dark fab>
                <v-icon v-if="!fab">mdi-alarm</v-icon>
                <v-icon v-else>mdi-close</v-icon>
              </v-btn>
            </template>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="#e0b517" dark fab small  @click="deliverHoursHandler" v-on="on">
                  <v-icon>mdi-check</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('deliver') }}</span>
            </v-tooltip>
            <v-btn fab dark small color="green" @click="openFormHandler($event)">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-speed-dial>
        </v-row>
      </v-container>
    </v-card-text>
    <!-- <v-menu :close-on-click="false" persistent v-model="showNormalHourMenu" top :position-x="positionX" :position-y="positionY" transition="scale-transition"
      :close-on-content-click="false" v-if="$vuetify.breakpoint.mdAndUp">
      <normal-hours :hoursOnDay="normalHoursList" class="normal_hours_menu" :hourObj="hourObj" :worktypes="getWorktypes" :users="getUsers" :tasks="listOfTasks"
        :currentProjects="listOfProjects" :timerFormSettings="getTimerFormSettings" :timecodes="getTimeCodes" :storedCustomer="storedCustomer" :storedProject="storedProject"
        ref="normalHoursMenu">
      </normal-hours>
    </v-menu> -->
    <v-dialog class="normal_hour_dialog" persistent v-model="showNormalHourMenu" width="500" style="overflow: hidden">
      <normal-hours v-if="showNormalHourMenu" :hoursOnDay="normalHoursList" class="normal_hours_menu" v-model="hourObj" :worktypes="getWorktypes" :users="getUsers" :tasks="listOfTasks"
        :currentProjects="listOfProjects" :timerFormSettings="getTimerFormSettings" :timecodes="getTimeCodes" :storedCustomer="storedCustomer" :storedProject="storedProject">
      </normal-hours>
    </v-dialog>
    <v-dialog v-model="commentsHistoryDialog" width="500px">
      <v-card flat class="recipient">
        <v-card-title class="pa-0">
          <v-toolbar :color="$vuetify.theme.dark ? 'black' : systemDetails.themecolor" :dark="$vuetify.theme.dark" height="55" :style="`color: ${$vuetify.theme.dark ? 'white' : systemDetails.textcolor}`">
            <v-toolbar-title class="body">{{ $t('notapprovedCommentsHistory') }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-icon @click="commentsHistoryDialog = false" :color="$vuetify.theme.dark ? 'white' : systemDetails.textcolor">mdi-close-circle-outline</v-icon>
          </v-toolbar>
        </v-card-title>
        <v-card-text class="pa-0">
          <v-container fluid>
            <v-row no-gutters>
              <v-col cols="12" class="flex-grow-1 flex-shrink-0 pa-0">
                <v-responsive class="overflow-y-hidden fill-height">
                  <v-card flat class="d-flex flex-column fill-height">
                    <v-card-text class="overflow-y-auto pa-0" v-if="listOfComments.length">
                      <template v-for="(comment, i) in listOfComments">
                        <div :key="i">
                          <v-avatar size="25px" :color="comment.userobject[0].profile ? '' : 'indigo'" class="mx-2">
                            <img v-if="comment.userobject[0].profile" alt="Avatar" :src="$formatter.getAppImageURL() +'/' + comment.userobject[0].profile">
                            <span v-else class="caption white--text">
                              {{ comment.userobject[0].name.match(/(\b\S)?/g).join("").match(/(^\S|\S$)?/g).join("").toUpperCase() }}
                            </span>
                          </v-avatar>
                          <v-chip label color="grey lighten-1" class="pa-1 pl-2 mb-1 black--text caption" :ripple="false">
                            {{ comment.message }}
                          </v-chip>
                        </div>
                        <p class="ml-2 caption font-weight-medium" :key="`date_${i}`">
                          {{ $formatter.formatDate(comment.sentdate, '', `${userDetails.dateformat} HH:mm`) }}
                        </p>
                      </template>
                    </v-card-text>
                    <v-card-text v-else>
                       <v-alert dense
                        outlined class="ma-1"
                        type="warning"
                        prominent
                        border="left"
                      >
                        {{ $t('noCommentsAdded') }}
                      </v-alert>
                    </v-card-text>
                    <!-- <v-card-text class="flex-shrink-1">
                      <v-form ref="commentForm">
                        <v-textarea v-model="replyObj.message" :label="$t('typeHere')" type="text" rows="3" no-details outlined :append-outer-icon="commentsLoader ? 'mdi-spin mdi-loading' : 'mdi-send'"
                          @click:append-outer="saveComment()" hide-details :rules="$_requiredValidation"/>
                      </v-form>
                    </v-card-text> -->
                  </v-card>
                </v-responsive>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
/* eslint-disable */
import { mapGetters } from 'vuex'
import CONSTANTS from '@/assets/json/constants.json'
import { decrypt } from '../../utils/crypto'
export default {
  data () {
    return {
      MODULE: 'hours',
      positionX: 0,
      positionY: 0,
      customWeek: this.$moment().isoWeek(),
      customYear: this.$moment().isoWeekYear(),
      listOfDays: [],
      projectsForComputed: [],
      listOfProjects: [],
      localeLang: 'no',
      startOfWeek: 0,
      endOfWeek: 0,
      listOfHourEntries: [],
      getHolidays: [],
      showNormalHourMenu: false,
      normalHoursList: [],
      hourObj: {
        id: 0,
        datePicker: null,
        projectid: null,
        customerSearch: '',
        projectSearch: ''
      },
      monthName: '',
      activeUser: {},
      listOfWorkTypes: [],
      listOfTasks: [],
      reloading: false,
      /* data for timer pwa design change */
      fieldsLoading: false,
      hoursListWrapper: [],
      totalHoursInWeek: 0,
      totalHourBank: 0,
      fab: false,
      constants: CONSTANTS,
      storedProject: [],
      storedCustomer: [],
      showDetails: false,
      totalHours: [],
      totalAbsenceHours: '0,00',
      absenceHours: 0,
      commentsLoader: false,
      listOfComments: [],
      commentsHistoryDialog: false,
      reInit: 0
    }
  },
  components: {
    'normal-hours': () => import('./HourTypes/NormalHour')
  },
  beforeMount () {
    this.$store.dispatch('getTimerSettings')
    if (this.$i18n.locale === 'no') this.localeLang = 'nb'
    else this.localeLang = 'en'
    var weekNo = +window.localStorage.getItem('Businessflow365PWA_weekno')
    var year = +window.localStorage.getItem('Businessflow365PWA_year')
    if (weekNo && year) {
      this.startOfWeek = this.$moment().isoWeekYear(year).isoWeek(weekNo).startOf('isoweek')
      this.endOfWeek = this.$moment().isoWeekYear(year).isoWeek(weekNo).endOf('isoweek')
    } else {
      this.startOfWeek = this.$moment().isoWeekYear(this.$moment().year()).isoWeek(this.$moment().isoWeek()).startOf('isoweek')
      this.endOfWeek = this.$moment().isoWeekYear(this.$moment().year()).isoWeek(this.$moment().isoWeek()).endOf('isoweek')
      this.setValuesInLocalStorage(this.$moment())
    }
  },
  computed: {
    ...mapGetters(['getWorktypes', 'getUsers', 'getTimerFormSettings', 'getTimeCodes', 'userDetails', 'systemDetails', 'getTimerSettings']),
    loadDays () {
      this.listOfDays = []
      this.loopWeek = this.startOfWeek
      this.customWeek = this.$moment(this.endOfWeek).isoWeek()
      this.customYear = this.$moment(this.endOfWeek).isoWeekYear()
      for (let i = 1; i <= 7; i++) {
        this.listOfDays.push({
          'short_day': this.$moment(this.loopWeek).locale(this.localeLang).format('ddd'),
          'date': this.$moment(this.loopWeek).format(this.userDetails.dateformat),
          'display_date': this.$moment(this.loopWeek).format('DD'),
          'monthName': this.$moment(this.loopWeek).format('MMMM'),
          'is_show': false,
          'is_today_before': this.$moment(this.$moment(this.loopWeek)).isBefore(this.$moment(), 'day')
        })
        this.loopWeek = this.startOfWeek.clone().add(i, 'd')
      }
      this.monthName = this.listOfDays[0].monthName
      const lastDateMonthName = this.listOfDays[this.listOfDays.length - 1].monthName
      if (this.listOfDays[0].monthName !== lastDateMonthName) this.monthName += `-${lastDateMonthName}`
      this.setValuesInLocalStorage(this.$moment(this.endOfWeek))
    },
    weekValidation () {
      return [
        val => /^[0-9]+?[0-9]*$/.test(val) || this.$t('inValid'),
        val => val <= 53 || this.$t('inValid')
      ]
    },
    yearValidation () {
      return [
        val => /^[0-9]+?[0-9]*$/.test(val) || this.$t('inValid'),
        val => ((val <= 9999) && (val > 999)) || this.$t('inValid')
      ]
    },
    hoursList: {
      get () {
        this.getUsers.find(user => { user._id === this.activeUser._id ? this.activeUser.normalworkinghours = user.normalworkinghours : false })
        this.hoursListWrapper = this.$formatter.cloneVariable(this.constructCalendarData())
        this.totalHours = this.hoursListWrapper.map(x => x.hours)
        return this.hoursListWrapper
      },
      set (value) {
        this.hoursListWrapper = value
      }
    },
    tableHeaders () {
      if (!this.getTimerFormSettings) return []
      return [
        { text: 'customer', value: 'customer_name', isShow: this.getTimerFormSettings.customer },
        { text: 'project', value: 'project_name', isShow: this.getTimerFormSettings.project },
        { text: 'worktype', value: 'worktype_name', isShow: this.getTimerFormSettings.worktype },
        { text: 'task', value: 'task_name', isShow: this.getTimerFormSettings.task },
        { text: 'timeCode', value: 'timecode_value', isShow: this.getTimerFormSettings.timecode }, /* it will be a dropdown field - add settings for time code with isactive & isdefault */
        { text: 'startTime', value: 'startTime', isShow: this.getTimerFormSettings.start_end_time },
        { text: 'endTime', value: 'endTime', isShow: this.getTimerFormSettings.start_end_time },
        { text: 'amount', value: 'hours', type: 'number', isShow: true },
        { text: 'invoiceAmount', value: 'toinvoice', type: 'number', isShow: this.getTimerFormSettings.to_invoice },
        { text: 'comments', value: 'comments', isShow: this.getTimerFormSettings.comments },
        { text: 'internalComments', value: 'internalcomments', isShow: this.getTimerFormSettings.internalcomments },
        { text: '', value: 'actions', isShow: true }
      ].filter((item) => item.isShow)
    }
  },
  watch: {
    '$i18n.locale' (val) {
      if (this.$i18n.locale === 'no') this.localeLang = 'nb'
      else this.localeLang = 'en'
      this.loadDays
    },
    // 'hourObj.projectid' (val) {
    //   if (val) {
    //     console.log('ccccc', val)
    //     this.$api.execute('get', `tasks/get_by_project/${val}`).then(response => {
    //       // let userAssignedTasks = response.data
    //       // if (this.activeUser) {
    //       //   userAssignedTasks = response.data.filter(x => x.assigned_to === this.activeUser._id)
    //       // }
    //       this.listOfTasks = this.$formatter.cloneVariable(response.data)
    //     })
    //   }
    // },
    'showNormalHourMenu' (val) {
      if (!val) {
        this.$refs.normalHoursMenu?.$refs?.hourReference?.$refs?.validateForm.reset()
        this.hourObj._id = undefined
      }
    }
  },
  mounted () {
    this.activeUser = this.$cookie.get(process.env.VUE_APP_USER) ? decrypt(JSON.parse(this.$cookie.get(process.env.VUE_APP_USER))) : {}
    this.setProjects()
    const text = 'deleteMsg'
    this.$eventBus.$on('deleteSuccess', (data) => {
      if (data === this.MODULE) {
        this.$root.$emit('snackbar', { snackbar: true, color: 'success', text })
        this.$store.commit('hideDialog')
        this.$eventBus.$emit('closeForm')
        this.getAllHourEntries()
      }
    })

    this.$eventBus.$on('closeForm', () => {
      this.showNormalHourMenu = false
      this.hourObj._id = undefined
      this.reInit += 1
    })

    this.$eventBus.$on('editSingleEntry', ({ id, isSoTask, worktypeid } = {}) => {
      if (isSoTask) {
        const soTask = this.listOfHourEntries.find(x => x._id === id)
        let model = {
          comments: soTask.task,
          date: soTask.date,
          hours: soTask.hours,
          projectid: soTask.projectid,
          projectmemberid: soTask.userid,
          isSoTask: true,
          soTaskId: soTask.id
        }
        model.worktypeid = soTask.worktype_id ? model.worktype_id : worktypeid
        const transformNumber = { number: { list: ['hours'], format: 'replaceDotWithComma' } }
        model = this.$formatter.formatModel(model, transformNumber)
        const transformData = { date: { list: ['date'], from: 'YYYY.MM.DD', to: 'DD.MM.YYYY' } }
        model = this.$formatter.formatModel(model, transformData)
        this.hourObj = model
      } else {
        this.getSingleRecordHandler({ id })
      }
    })
    this.$eventBus.$on('getAllHourLogs', () => {
      this.getAllHourEntries()
    })
    this.$root.$emit('removeTransition', true)
    this.$store.dispatch('getWorktypes').then(() => {
      this.setDefaultValues({ prop: 'worktypeid', list: this.getWorktypes })
    })
    this.$store.dispatch('getUsers')
    this.$store.dispatch('getTimerFormSettings')
    this.$store.dispatch('getTimeCodes')
  },
  methods: {
    expansionToggle () {
      setTimeout(() => {
        this.showDetails = false
      }, 500)
    },
    setProjects () {
      this.reloading = true
        this.$api.execute('get', this.userId && this.getTimerSettings.filterprojectsbyassignee ? `projects/get_current_projects?user_id=${this.userDetails._id}&show_completed=false` : 'projects/get_current_projects?show_completed=false')
      // this.$api.execute('get', 'projects/get_current_projects')
        .then((response) => {
          response.data.forEach((data) => {
            data.projectName = data.number ? `${data.number} - ${data.name}` : `${data.name}`
          })
          this.listOfProjects = response.data
          this.getAllHourEntries()
        })
    },
    prevWeek () {
      this.startOfWeek = this.$moment(this.startOfWeek).subtract(1, 'weeks')
      this.endOfWeek = this.$moment(this.endOfWeek).subtract(1, 'weeks')
      this.getAllHourEntries()
    },
    nextWeek () {
      this.startOfWeek = this.$moment(this.startOfWeek).add(1, 'weeks')
      this.endOfWeek = this.$moment(this.endOfWeek).add(1, 'weeks')
      this.getAllHourEntries()
    },
    goToToday () {
      this.startOfWeek = this.$moment().isoWeekYear(this.$moment().year()).isoWeek(this.$moment().isoWeek()).startOf('isoweek')
      this.endOfWeek = this.$moment().isoWeekYear(this.$moment().year()).isoWeek(this.$moment().isoWeek()).endOf('isoweek')
      this.getAllHourEntries()
    },
    goToCustomWeek () {
      if (this.$refs.weekNavigation.validate()) {
        this.startOfWeek = this.$moment().isoWeekYear(this.customYear).isoWeek(this.customWeek).startOf('isoweek')
        this.endOfWeek = this.$moment().isoWeekYear(this.customYear).isoWeek(this.customWeek).endOf('isoweek')
        this.getAllHourEntries()
      }
    },
    getWeekTotalHours () {
      let normalHours = 0
      let absenceHours = 0
      this.totalHours.forEach(hours => {
        hours.forEach(element => {
          if (element.type === 'working') normalHours += element.hours
          else absenceHours += element.hours
        })
      })
      this.totalAbsenceHours = this.$formatter.replaceDotWithComma(parseFloat(absenceHours).toFixed(2))
      return this.$formatter.replaceDotWithComma(parseFloat(normalHours).toFixed(2))
    },
    getAllHourEntries () {
      const modelTimebank = [{ year: this.$moment(this.endOfWeek).isoWeekYear(), week: this.$moment(this.endOfWeek).isoWeek() }]
      this.$api.execute('post', `timebankperweeks/get_by_weeks/${this.userDetails._id}`, modelTimebank).then(response => {
        if (response && response.data) {
          this.absenceHours = response.data.leavebank ? this.$formatter.replaceDotWithComma(response.data.leavebank.amount) : 0
          if (response.data.timebankperweeks.length) {
            const hourBankIndex = response.data.timebankperweeks[response.data.timebankperweeks.length - 1]
            if (hourBankIndex && hourBankIndex.transferredhours) {
              this.totalHourBank = hourBankIndex.amount - hourBankIndex.transferredhours
            } else this.totalHourBank = hourBankIndex.amount
          }
        }
      })
      const start = this.$moment(this.startOfWeek).subtract(1,'days').format('YYYY-MM-DDTHH:mm:ss')
      // const start = this.$formatter.formatDate(this.startOfWeek, this.userDetails.dateformat, 'YYYY-MM-DDTHH:mm:ss')
      const end = this.$moment(this.startOfWeek).add(7, 'days').format('YYYY-MM-DDTHH:mm:ss')

      // previous live code
      // const start = this.$formatter.formatDate(this.startOfWeek, 'DD.MM.YYYY', 'YYYY-MM-DDTHH:mm:ss')
      // const end = this.$moment(this.startOfWeek).add(6, 'days').format('YYYY-MM-DDTHH:mm:ss')
      // const start = this.$moment(this.startOfWeek).subtract(1,'days').format('YYYY-MM-DDTHH:mm:ss')
      // const end = this.$moment(this.startOfWeek).add(7,'days').format('YYYY-MM-DDTHH:mm:ss')

      const model = { start: start, end: end, users: [this.userDetails._id] }
      this.fieldsLoading = true
      this.$api.execute('post', `${this.MODULE}/filter`, model)
        .then((response) => {
          const appHourEntries = response.data.map((item) => ({
            ...item
            // date: this.$formatter.fromUtcToLocal(item.date, 'DD.MM.YYYYTHH.mm.ss', 'DD.MM.YYYYTHH.mm.ss'),
            // start_date: this.$formatter.fromUtcToLocal(item.start_date, 'DD.MM.YYYYTHH.mm.ss', 'DD.MM.YYYYTHH.mm.ss'),
            // end_date: this.$formatter.fromUtcToLocal(item.end_date, 'DD.MM.YYYYTHH.mm.ss', 'DD.MM.YYYYTHH.mm.ss'),
          }))
          appHourEntries.forEach((hour) => {
            // hour.startTime = (hour.start_date) ? this.$formatter.formatDate(hour.start_date, 'DD.MM.YYYYTHH.mm.ss', 'HH:mm') : ''
            // hour.endTime = (hour.end_date) ? this.$formatter.formatDate(hour.end_date, 'DD.MM.YYYYTHH.mm.ss', 'HH:mm') : ''

            const starttime = (hour.start_date) ? this.$formatter.formatDate(hour.start_date, 'DD.MM.YYYYTHH.mm.ss', 'HH:mm:ss') : null
            const endtime = (hour.end_date) ? this.$formatter.formatDate(hour.end_date, 'DD.MM.YYYYTHH.mm.ss', 'HH:mm:ss') : null
            const diffHours = this.$moment(`${endtime}`, 'HH:mm:ss').diff(this.$moment(`${starttime}`, 'HH:mm:ss'), 'hours', true)
            hour.startTime = (diffHours.toFixed(2) === hour.hours.toFixed(2)) ? this.$formatter.formatDate(hour.start_date, 'DD.MM.YYYYTHH.mm.ss', 'HH:mm') : ''
            hour.endTime = (diffHours.toFixed(2) === hour.hours.toFixed(2)) ? this.$formatter.formatDate(hour.end_date, 'DD.MM.YYYYTHH.mm.ss', 'HH:mm') : ''
          })
          const normalHours = []
          const listOfAbsenceHours = []
          appHourEntries.filter((hour) => {
            if (hour.type === this.NON_WORKING) listOfAbsenceHours.push(hour)
            else normalHours.push(hour)
          })
          const absenceDates = []
          // To Separate list of listabsence to duplicate records
          // if (listOfAbsenceHours.length) {
          //   listOfAbsenceHours.forEach((absenceHour) => {
          //     const startDate = this.$moment(absenceHour.start_date, 'DD.MM.YYYYTHH:mm:ss')
          //     const endDate = this.$moment(absenceHour.end_date, 'DD.MM.YYYYTHH:mm:ss')
          //     const datesArray = this.enumerateDaysBetweenDates(startDate, endDate)
          //     if (datesArray.length > 1) {
          //       datesArray.forEach(item => {
          //         const start_date = this.$formatter.formatDate(item, this.userDetails.dateformat, 'DD.MM.YYYYTHH:mm:ss')
          //         const end_date = this.$formatter.formatDate(item, this.userDetails.dateformat, 'DD.MM.YYYYTHH:mm:ss')
          //         absenceDates.push({ ...absenceHour, start_date, end_date, date: start_date })
          //       })
          //     } else absenceDates.push(absenceHour)
          //   })
          // }
          if (listOfAbsenceHours.length) {
            listOfAbsenceHours.forEach((absenceHour) => {
              const startDate = this.$moment(absenceHour.start_date, 'DD.MM.YYYYTHH.mm.ss')
              const endDate = this.$moment(absenceHour.end_date, 'DD.MM.YYYYTHH.mm.ss')
              const datesArray = this.enumerateDaysBetweenDates(startDate, endDate)
              if (datesArray.length > 1) {
                datesArray.forEach(item => {
                  const start_date = this.$formatter.formatDate(item, this.userDetails.dateformat, 'DD.MM.YYYYTHH.mm.ss')
                  const end_date = this.$formatter.formatDate(item, this.userDetails.dateformat, 'DD.MM.YYYYTHH.mm.ss')
                  absenceDates.push({ ...absenceHour, start_date, end_date, date: start_date })
                })
              } else absenceDates.push(absenceHour)
            })
          }
          this.listOfHourEntries = [...normalHours, ...absenceDates]
          this.loadDays
        })
        .finally(() => {
          this.fieldsLoading = false
          this.reloading = false
        })
    },
    setDefaultValues (data) {
      const { list, prop } = data
      const item = list.find(x => x.isdefault)
      if (item) {
        const model = Object.assign({ ...this.hourObj }, { [prop]: item._id })
        this.hourObj = model
      }
    },
    openFormHandler (event, date) { /* projectId, userid, taskid , date, hoursList, event */
      event.stopPropagation()
      this.showNormalHourMenu = true
      // pre-select fields
      if (this.getTimerFormSettings.project || this.getTimerFormSettings.customer) {
        let timerPresets = window.localStorage.getItem('PDCRMPWA_timerpreset') // Load values from last saved form
        if (timerPresets) {
          timerPresets = JSON.parse(timerPresets)
          this.storedCustomer = timerPresets.customer
          this.storedProject = timerPresets.project
          if (this.getTimerFormSettings.customer && timerPresets.customer && timerPresets.customer.length > 0) this.hourObj.customerid = timerPresets.customerid
          if (this.getTimerFormSettings.project && timerPresets.project && timerPresets.project.length > 0) this.hourObj.projectid = timerPresets.projectid
        }
      }
      this.getWorktypes.map((worktype) => worktype.disabled = false)
      let worktype = this.getWorktypes.find(x => x.isdefault === true)
      const currentUser = (this.userDetails && this.userDetails._id) ? this.getUsers.find(x => x._id === this.userDetails._id) : null

      const hours = (worktype && worktype.isabsence)
        ? (currentUser && currentUser.normalworkinghours ? currentUser.normalworkinghours : '0,00')
        : ((!this.getTimerFormSettings.start_end_time && currentUser && currentUser.normalworkinghours)
          ? currentUser.normalworkinghours
          : '0,00')

      this.hourObj = {
        id: 0,
        state: null,
        start_date: this.$formatter.formatDate(date,this.userDetails.dateformat, this.userDetails.dateformat),
        end_date: this.$formatter.formatDate(date, this.userDetails.dateformat, this.userDetails.dateformat),
        date: date || this.$moment().format(this.userDetails.dateformat),
        hours,
        toinvoice: (hours && hours !== '0,00') ? hours : null,
        userid: this.activeUser._id || ''
      }
    },
    async getSingleRecordHandler ({ id }) {
      this.$api.execute('post', 'hours/filter', { ids: [id] })
        .then(({ data }) => {
          let hour = data[0]
          hour.projectsListOnEdit = hour.projectid ? [{ projectName: hour.project_name && hour.project_number ? `${hour.project_number} - ${hour.project_name}` : hour.project_name, _id: hour.projectid || '' }] : this.customerId ? this.listOfProjects : []
          // enable once start_date & end_date fields added
          if (this.getTimerFormSettings && this.getTimerFormSettings.start_end_time) {
            const starttime = this.$formatter.formatDate(hour.start_date, 'DD.MM.YYYYTHH.mm.ss', 'HH:mm:ss')
            const endtime = this.$formatter.formatDate(hour.end_date, 'DD.MM.YYYYTHH.mm.ss', 'HH:mm:ss')
            const diffHours = this.$moment(`${endtime}`, 'HH:mm:ss').diff(this.$moment(`${starttime}`, 'HH:mm:ss'), 'hours', true)
            hour.starttime = (diffHours.toFixed(2) === hour.hours.toFixed(2)) ? this.$formatter.formatDate(hour.start_date, 'DD.MM.YYYYTHH.mm.ss', 'HH:mm') : ''
            hour.endtime = (diffHours.toFixed(2) === hour.hours.toFixed(2)) ? this.$formatter.formatDate(hour.end_date, 'DD.MM.YYYYTHH.mm.ss', 'HH:mm') : ''
          }
          hour.hours = parseFloat(hour.hours).toFixed(2)
          hour.datePicker = null
          hour.projectmemberid = hour.userid
          const transformNumber = { number: { list: ['hours'], format: 'replaceDotWithComma' } }
          hour = this.$formatter.formatModel(hour, transformNumber)
          const transformData = { date: { list: ['date'], from: 'DD.MM.YYYYTHH:mm:ss', to: this.userDetails.dateformat } }
          const model = this.$formatter.formatModel(hour, transformData)
          if (data) this.hourObj = model
          this.showNormalHourMenu = true
          if (this.hourObj.type) {
            this.getWorktypes.forEach(x => {
              if (this.hourObj.type === this.NON_WORKING && x.isabsence) x.disabled = false
              else if (this.hourObj.type === this.WORKING && !x.isabsence) x.disabled = false
              else x.disabled = true
            })
          }
        })
    },
    deleteHourHandler (id) {
      const ids = [id]
      const payload = { ids, url: `${this.MODULE}/delete_multiple`, module: this.MODULE }
      this.$store.commit('showDeleteDialog', payload)
    },
    deliverHoursHandler () {
      let hours = this.$formatter.cloneVariable(this.listOfHourEntries)
      hours = hours.filter(x => x.state === CONSTANTS.NOT_DELIVERED && x.hours)
      const hourIds = hours.map(x => x._id)
      const model = { ids: hourIds, parameters: { state: CONSTANTS.DELIVERED } }
      if (this.$formatter.isArrayHasData(hourIds)) {
        this.$api.execute('put', `${this.MODULE}/bulk_update`, model).then(() => {
          this.getAllHourEntries()
          this.$root.$emit('snackbar', { snackbar: true, color: 'success', text: 'deliveredSuccessfully' })
        })
      } else this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'noHours' })
    },
    getHoursSum (hours, type) {
      const sum = this.$formatter.getSumBy(hours, x => x[type])
      return this.$formatter.replaceDotWithComma(sum)
    },
    getStatusColorHandler (hourList) {
      if (hourList.some(item => item.state === CONSTANTS.NOT_DELIVERED)) return 'not_delivered'
      else if (hourList.some(item => item.state === CONSTANTS.NOT_APPROVED)) return 'not_approved'
      else if (hourList.some(item => item.state === CONSTANTS.DELIVERED)) return 'delivered'
      else if (hourList.some(item => item.state === CONSTANTS.APPROVED)) return 'approved'
    },
    setValuesInLocalStorage (week) {
      window.localStorage.setItem('Businessflow365PWA_weekno', week.isoWeek())
      window.localStorage.setItem('Businessflow365PWA_year', week.isoWeekYear())
    },
    /* pwa design data methods added */
    constructCalendarData () {
      const listOfHours = this.$formatter.cloneVariable(this.listOfHourEntries)
      const listOfDays = this.$formatter.cloneVariable(this.listOfDays)
      // var listOfStatus = []
      for (let i = 0; i < listOfDays.length; i++) {
        var total = 0
        var totalhourbank = 0
        var listStartTime = []
        var listOfEndTime = []
        // const dayHours = listOfHours !== null ? listOfHours.filter((hour) => this.$formatter.formatDate((hour.isabsence ? hour.date : hour.start_date), 'DD.MM.YYYYTHH:mm:ss', this.userDetails.dateformat) === listOfDays[i].date) : []
        const dayHours = listOfHours !== null ? listOfHours.filter((hour) => this.$formatter.formatDate((hour.isabsence ? hour.date : hour.start_date), '', this.userDetails.dateformat) === listOfDays[i].date) : []
        const groupedHours = this.$formatter.groupBy(dayHours, 'type', 'working')
        const types = [CONSTANTS.WORKING, CONSTANTS.NON_WORKING]
        let newHours = []
        types.forEach(type => {
          if (groupedHours[type] && groupedHours[type].length > 0) newHours = [...newHours, ...groupedHours[type]]
        })
        listOfDays[i].hours = newHours
        for (let j = 0; j < listOfDays[i].hours.length; j++) {
          const listOfStatus = []
          total += listOfDays[i].hours[j].hours
          totalhourbank += listOfDays[i].hours[j].hourbank
          listOfDays[i].hours[j].break_hr = this.$formatter.replaceDotWithComma(listOfDays[i].hours[j].break_hr)
          if (listOfDays[i].hours[j].startTime === '00:00' && listOfDays[i].hours[j].endTime === '00:00') {
            listOfDays[i].hours[j].startTime = listOfDays[i].hours[j].startTime === '00:00' ? '' : this.$formatter.formatDate(listOfDays[i].hours[j].start_date, 'DD.MM.YYYY HH:mm:ss', 'HH:mm')
            listOfDays[i].hours[j].endTime = listOfDays[i].hours[j].endTime === '00:00' ? '' : this.$formatter.formatDate(listOfDays[i].hours[j].end_date, 'DD.MM.YYYY HH:mm:ss', 'HH:mm')
          }
          if (this.$formatter.formatDate(listOfDays[i].hours[j].start_date, 'DD.MM.YYYYT00:00:00', this.userDetails.dateformat) === this.$formatter.formatDate(listOfDays[i].hours[j].end_date, 'DD.MM.YYYYT00:00:00', this.userDetails.dateformat)) {
            listStartTime.push(this.$moment(listOfDays[i].hours[j].start_date, 'DD.MM.YYYY[T]HH.mm.ss').format('HH.mm'))
            listOfEndTime.push(this.$moment(listOfDays[i].hours[j].end_date, 'DD.MM.YYYY[T]HH.mm.ss').format('HH.mm'))
          }
          listOfDays[i].startingTime = Math.min(...listStartTime)
          listOfDays[i].endingTime = Math.max(...listOfEndTime)
          const checkStatus = listOfDays[i].hours[j]
          switch (checkStatus.state) {
            case 'approved': listOfStatus.push({ id: 1, name: 'APPROVED' })
              break
            case 'not_approved': listOfStatus.push({ id: 2, name: 'NOT_APPROVED' })
              break
            case 'delivered': listOfStatus.push({ id: 3, name: 'DELIVERED' })
              break
            case 'not_delivered': listOfStatus.push({ id: 4, name: 'NOT_DELIVERED' })
              break
          }
          if (listOfStatus.find(x => x.id === 4)) {
            listOfDays[i].statusName = 'NOT_DELIVERED'
          } else if (listOfStatus.find(x => x.id === 3)) {
            listOfDays[i].statusName = 'DELIVERED'
          } else if (listOfStatus.find(x => x.id === 2)) {
            listOfDays[i].statusName = 'NOT_APPROVED'
          } else if (listOfStatus.find(x => x.id === 1)) {
            listOfDays[i].statusName = 'APPROVED'
          }
        }
        listOfDays[i].total = total
        if (this.$moment(listOfDays[i].date, this.userDetails.dateformat).isoWeekday() === 6 || this.$moment(listOfDays[i].date, this.userDetails.dateformat).isoWeekday() === 7) listOfDays[i].totalhourbank = 0
        else if (this.activeUser.normalworkinghours) listOfDays[i].totalhourbank = listOfDays[i].is_today_before ? listOfDays[i].total - this.activeUser.normalworkinghours : totalhourbank
        else listOfDays[i].totalhourbank = 0
      }
      const modelTimebank = [{ year: this.$moment(this.endOfWeek).isoWeekYear(), week: this.$moment(this.endOfWeek).isoWeek() }]
      this.totalHoursInWeek = 0
      this.totalHourBank = 0
      for (let i = 0; i < listOfDays.length; i++) {
        this.totalHoursInWeek += listOfDays[i].total
      }
      // this.$api.execute('post', `timebankperweeks/get_by_weeks/${this.userDetails._id}`, modelTimebank).then(response => {
      //   if (response && response.data) {
      //     this.absenceHours = response.data.leavebank ? this.$formatter.replaceDotWithComma(response.data.leavebank.amount) : 0
      //     if (response.data.timebankperweeks.length) {
      //       const hourBankIndex = response.data.timebankperweeks[response.data.timebankperweeks.length - 1]
      //       if (hourBankIndex && hourBankIndex.transferredhours) {
      //         this.totalHourBank = hourBankIndex.amount - hourBankIndex.transferredhours
      //       } else this.totalHourBank = hourBankIndex.amount
      //     }
      //   }
      // })
      return listOfDays
    },
    enumerateDaysBetweenDates (startDate, endDate) {
      const now = startDate
      const dates = []
      while (now.isSameOrBefore(endDate)) {
        dates.push(now.format(this.userDetails.dateformat))
        now.add(1, 'days')
      }
      return dates
    },
    getCommentsHistory (hourId) {
      this.commentsLoader = true
      this.$api.execute('get', `timerconversations/get_comment/${hourId}`).then(response => {
        this.listOfComments = response.data
      }).finally(() => {
        this.commentsLoader = false
        this.commentsHistoryDialog = true
      })
    }
  },
  beforeDestroy () {
    this.$eventBus.$off('closeForm')
    this.$eventBus.$off('editSingleEntry')
    this.$eventBus.$off('deleteSuccess')
    this.$eventBus.$off('getAllHourLogs')
  }
}
</script>
<style>
.reduceHeight {
  height: 10px;
}
.delivered {
  /* background-color: #e0b517; */
  color: #e0b517 !important;
}
.not_delivered {
  background-color: lightgray !important
}
.not_approved {
  background-color: #ea1616;
  color: white;
}
.approved {
  /* background-color: rgba(86, 177, 62, 0.65); */
  color: rgba(86, 177, 62, 0.65) !important;
}
.today_color {
  background-color: #2196f357 !important;
}
  .timer_module table {
    width: 100%;
    height: 60px;
    border: 1px solid whitesmoke;
    /* box-shadow: 4px 5px 15px whitesmoke; */
  }
  .timer_module .list--items {
    border: 1px solid #e0e0e0;
  }
  .sub_table_border {
    border: 1px solid whitesmoke;
  }
  .timer_module .v-form > .container > .layout:only-child {
    margin: 0 !important;
  }
  .timer_module .v-expansion-panel::before{
    box-shadow: none;
  }
  .timer_module .v-expansion-panels{
    border: 1px solid rgba(0, 0, 0, 0.1);
  }
  .timer--wrapper .v-speed-dial {
    position: absolute;
  }
  .day-locked {
    pointer-events: none;
    opacity: 0.6;
  }
  .timer_table > .v-data-table__wrapper > table > tbody > tr > td {
    padding: 0px 3px !important;
  }
  .timer_speed_dial_position {
    bottom: 65px !important;
    right: 1vh !important;
  }
  .customtext .theme--light.v-label, .customtext fieldset, .customtext input {
    color: white !important;
  }
  .customtext .v-input__slot{
    min-height: 32px !important;
  }
  .customtext {
    width: 58px !important;
  }
  .hours-table table thead tr th, .hours-table table tbody tr td {
    border-right: 1px solid rgba(0, 0, 0, 0.1);
  }
  .hours-table table {
    border: 1px solid rgba(0, 0, 0, 0.1);
  }
  .normal_hour_dialog.v-dialog {
    max-height: 82% !important;
  }
  .table-border {
    border-right: 1px solid rgba(0, 0, 0, 0.3);
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  }
  .timer_module {
    height: 100% !important;
    overflow-y: auto !important;
  }
  .timer-page {
    height: calc(var(--vh, 1vh) * 100 - 56px) !important;
    overflow: hidden;
  }
</style>
